<template>
    <div class="card lg:px-9">
        <TabMenu v-model:activeIndex="active" :model="items" />

        <div v-if="active === 0" class="mt-2">
            <DataTable v-model:filters="filters" :value="getReviews" stripedRows paginator :rows="100" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-content-between">
                    <h4>Reviews</h4>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </IconField>
                </div>
            </template>
            <template #empty> No reviews found. </template>

            <Column field="created_at" header="Date" style="max-width: 1rem">
                <template #body="{ data }">
                    <span>{{ convertDate(data) }}</span>
                </template>
            </Column>

            <Column field="names" header="User" style="max-width: 2rem">
                <template #body="{ data }">
                    <span>{{ data.firstname }} {{ data.lastname.toUpperCase() }}</span>
                </template>
            </Column>

            <Column field="ticker" header="Ticker" style="max-width: 1rem"></Column>

            <Column field="title" header="Title" style="max-width: 3rem"></Column>

            <Column field="score" header="Score" style="max-width: 1rem"></Column>

            <Column field="verifications" header="" style="max-width: 2rem">
                <template #body="{ data }">
                    <Tag v-if="data.enabled" class="mx-1 bg-green-400 text-white" value="">
                        <span>enabled</span>
                    </Tag>
                    <Tag v-else class="mx-1 bg-red-400 text-white" value="">
                        <span>Disabled</span>
                    </Tag>

                    <Tag v-if="data.description" class="mx-1 bg-blue-500 text-white" value="">
                        <span>description</span>
                    </Tag>
                </template>
            </Column>

            <Column headerStyle="width: 60px; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button @click="openReview(data)" type="button" icon="pi pi-angle-right" rounded />
                </template>
            </Column>
        </DataTable>
        </div>

        <div v-if="active === 1" class="mt-2">
            <create />
        </div>

        <div v-if="active === 2" class="mt-2">
            2
        </div>

        <Dialog v-model:visible="dialogUpdateReview" modal header="Update review" :style="{ width: '95%' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div>
                <div class="flex w-12">
                    <div class="flex flex-column gap-2">
                        <label for="ticker">Ticker* <span class="pi pi-search ml-1" style="font-size: .7rem"></span></label>
                        <InputText id="order" v-model="review.ticker" disabled placeholder="Ticker" />
                    </div>

                    <div class="flex flex-column gap-2 ml-2">
                        <label for="order">Score /100*</label>
                        <InputText id="order" v-model="review.score" :disabled='!review.user_uuid' placeholder="Score" />
                    </div>
                </div>

                <div class="flex mt-3 w-12">
                    <Textarea v-model="review.description" :disabled='!review.user_uuid' rows="5" cols="30" />
                </div>

                <Button
                    label="Update review"
                    severity="success"
                    class="mt-4"
                    @click="update()"
                    :disabled="!review.user_uuid || !review.ticker || !review.title || !review.score"
                />

                <Button
                    label="Delete review"
                    severity="danger"
                    class="mt-4 ml-2"
                    @click="deleteR()"
                />
            </div>
        </Dialog>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import create from '@/components/dashboard/modules/reviews/create.vue';
import { FilterMatchMode } from 'primevue/api';
import m from 'moment';

export default {
    components: { create },
    name: 'ReviewsPage',
    data() {
        return {
            active: 0,
            dialogUpdateReview: false,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            review: {
                id: null,
                user_uuid: null,
                score: null,
                description: null,
                ticker: null,
                title: null
            },
            items: [
                { label: 'Reviews', icon: 'pi pi-list' },
                { label: 'Add reviews', icon: 'pi pi-plus' },
                { label: 'Statistics', icon: 'pi pi-chart-pie' }
            ],
        };
    },
    mounted() {
        this.reviews();
    },
    computed: {
        ...mapGetters({
            getSearchUsers: 'getSearchUsers',
            getSearchTickers: 'getSearchTickers',
            getReviews: 'getReviews'
        }),
    },
    watch: {
        active() {
            if (this.active === 0) {
                this.reviews();
            }
        }
    },
    methods: {
        ...mapActions({
            searchUsers: 'searchUsers',
            searchTickers: 'searchTickers',
            reviews: 'reviews',
            updateReview: 'updateReview',
            deleteReview: 'deleteReview'
        }),
        convertDate(data) {
            return m(data.created_at).format('DD/MM/YYYY HH:mm');
        },
        openReview(r) {
            this.dialogUpdateReview = true;
            this.review = r;
        },
        async update() {
            await this.updateReview(this.review).then(() => {
                this.review = {
                    user_uuid: null,
                    score: null,
                    description: null,
                    ticker: null,
                    title: null
                }
                this.dialogUpdateReview = false;
            })
        },
        async deleteR() {
            await this.deleteReview(this.review.id).then(() => {
                this.dialogUpdateReview = false;
                this.review = {
                    user_uuid: null,
                    score: null,
                    description: null,
                    ticker: null,
                    title: null
                }
            })
        }
    }
};
</script>