<template>
    <div class="card lg:px-9">
        <DataTable v-model:filters="filters" :value="getUsersConnected" stripedRows paginator :rows="100" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-content-between">
                    <h4>Users Lives</h4>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </IconField>
                </div>
            </template>
            <template #empty> No users connected found. </template>

            <Column field="connected" header="" style="width: 30px">
                <template #body="{  }">
                    <span>
                        <i class="pi pi-circle-fill text-green-500"></i>
                    </span>
                </template>
            </Column>

            <Column field="created_at" header="Date" style="max-width: 2rem">
                <template #body="{ data }">
                    <span>{{ convertDate(data) }}</span>
                </template>
            </Column>

            <Column field="account_type" header="Type" dataType="boolean" style="max-width: 2rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-user': !data.account_type, 'pi-building': data.account_type }"></i>
                </template>
            </Column>

            <Column field="organization.name" header="Organization" dataType="boolean" style="max-width: 2rem"></Column>
            <Column field="firstname" header="Firstname" dataType="boolean" style="max-width: 2rem"></Column>
            <Column field="lastname" header="Lastname" dataType="boolean" style="max-width: 2rem"></Column>

            <Column field="subscriptions_informations" header="Subscriptions" dataType="boolean" style="max-width: 3rem">
                <template #body="{ data }">
                    <Tag class="mx-1 my-1" :value="s.label.slice(0, 1)" v-for="s, n in returnSubscriptionsCount(data.subscriptions_informations)" :key="n"></Tag>
                    <p v-if="returnSubscriptionsCount(data.subscriptions_informations).length === 0">-</p>
                </template>
            </Column>

            <Column field="verifications" header="" dataType="boolean" style="max-width: 3rem">
                <template #body="{ data }">
                    <Tag v-if="data.newsletter" class="mx-1 bg-green-400 text-white" value="">
                        <i class="pi pi-envelope"></i>
                    </Tag>
                    <Tag v-else class="mx-1 bg-red-400 text-white" value="">
                        <i class="pi pi-envelope"></i>
                    </Tag>

                    <Tag v-if="data.email_is_verified" class="mx-1 my-1 bg-green-400 text-white" value="Email">
                        <i class="pi pi-at"></i>
                    </Tag>
                    <Tag v-else class="mx-1 my-1 bg-red-400 text-white" value="Email">
                        <i class="pi pi-at"></i>
                    </Tag>

                    <Tag v-if="data.phone_is_verified" class="mx-1 my-1 bg-green-400 text-white" value="Phone">
                        <i class="pi pi-phone"></i>
                    </Tag>
                    <Tag v-else class="mx-1 my-1 bg-red-400 text-white" value="Phone">
                        <i class="pi pi-phone"></i>
                    </Tag>

                    <Tag v-if="data.kyc_is_verified" class="mx-1 my-1 bg-green-400 text-white" value="KYC">
                        <i class="pi pi-id-card"></i>
                    </Tag>
                    <Tag v-else class="mx-1 my-1 bg-red-400 text-white" value="KYC">
                        <i class="pi pi-id-card"></i>
                    </Tag>

                    <Tag v-if="data.banking_is_verified" class="mx-1 my-1 bg-green-400 text-white" value="Banking">
                        <i class="pi pi-dollar"></i>
                    </Tag>
                    <Tag v-else class="mx-1 my-1 bg-red-400 text-white" value="Banking">
                        <i class="pi pi-dollar"></i>
                    </Tag>
                </template>
            </Column>

            <Column headerStyle="width: 60px; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button @click="redirectToUser(data)" type="button" icon="pi pi-angle-right" rounded />
                </template>
            </Column>
        </DataTable>


        <DataTable v-model:filters="filters" :value="getUsers" stripedRows paginator :rows="100" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem" class="mt-3">
            <template #header>
                <div class="flex justify-content-between">
                    <h4>Users list</h4>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </IconField>
                </div>
            </template>
            <template #empty> No users found. </template>

            <Column field="connected" header="" style="width: 30px">
                <template #body="{ data }">
                    <span v-if="checkIsConnected(data)">
                        <i class="pi pi-circle-fill text-green-500"></i>
                    </span>
                    <span v-else>
                        <i class="pi pi-circle text-red-500">

                        </i></span>
                </template>
            </Column>

            <Column field="created_at" header="Date" style="max-width: 2rem">
                <template #body="{ data }">
                    <span>{{ convertDate(data) }}</span>
                </template>
            </Column>

            <Column field="account_type" header="Type" dataType="boolean" style="max-width: 2rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-user': !data.account_type, 'pi-building': data.account_type }"></i>
                </template>
            </Column>

            <Column field="organization.name" header="Organization" dataType="boolean" style="max-width: 2rem"></Column>
            <Column field="firstname" header="Firstname" dataType="boolean" style="max-width: 2rem"></Column>
            <Column field="lastname" header="Lastname" dataType="boolean" style="max-width: 2rem"></Column>

            <Column field="subscriptions_informations" header="Subscriptions" dataType="boolean" style="max-width: 3rem">
                <template #body="{ data }">
                    <Tag class="mx-1 my-1" :value="s.label.slice(0, 1)" v-for="s, n in returnSubscriptionsCount(data.subscriptions_informations)" :key="n"></Tag>
                    <p v-if="returnSubscriptionsCount(data.subscriptions_informations).length === 0">-</p>
                </template>
            </Column>

            <Column field="verifications" header="" dataType="boolean" style="max-width: 3rem">
                <template #body="{ data }">
                    <Tag v-if="data.newsletter" class="mx-1 bg-green-400 text-white" value="">
                        <i class="pi pi-envelope"></i>
                    </Tag>
                    <Tag v-else class="mx-1 bg-red-400 text-white" value="">
                        <i class="pi pi-envelope"></i>
                    </Tag>

                    <Tag v-if="data.email_is_verified" class="mx-1 my-1 bg-green-400 text-white" value="Email">
                        <i class="pi pi-at"></i>
                    </Tag>
                    <Tag v-else class="mx-1 my-1 bg-red-400 text-white" value="Email">
                        <i class="pi pi-at"></i>
                    </Tag>

                    <Tag v-if="data.phone_is_verified" class="mx-1 my-1 bg-green-400 text-white" value="Phone">
                        <i class="pi pi-phone"></i>
                    </Tag>
                    <Tag v-else class="mx-1 my-1 bg-red-400 text-white" value="Phone">
                        <i class="pi pi-phone"></i>
                    </Tag>

                    <Tag v-if="data.kyc_is_verified" class="mx-1 my-1 bg-green-400 text-white" value="KYC">
                        <i class="pi pi-id-card"></i>
                    </Tag>
                    <Tag v-else class="mx-1 my-1 bg-red-400 text-white" value="KYC">
                        <i class="pi pi-id-card"></i>
                    </Tag>

                    <Tag v-if="data.banking_is_verified" class="mx-1 my-1 bg-green-400 text-white" value="Banking">
                        <i class="pi pi-dollar"></i>
                    </Tag>
                    <Tag v-else class="mx-1 my-1 bg-red-400 text-white" value="Banking">
                        <i class="pi pi-dollar"></i>
                    </Tag>
                </template>
            </Column>

            <Column headerStyle="width: 60px; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button @click="redirectToUser(data)" type="button" icon="pi pi-angle-right" rounded />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import { filter } from 'lodash';
import m from 'moment';

export default {
    name: 'usersList',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            usersConnected: [],
        };
    },
    computed: {
        ...mapGetters({
            getUsers: 'getUsers',
            getUser: 'getUser',
            getUsersCon: 'getUsersConnected',
            getPings: 'getPings',
        }),
        getUsersConnected() {
            return this.usersConnected;
        }
    },
    watch: {
        async getPings() {
            await this.users().then(() => {
                this.usersConnected = [];

                this.getUsers.forEach((u) => {
                    if (this.getUsersCon.includes(u.ip)) {
                        this.usersConnected.push(u);
                    }
                })
            });
        }
    },
    methods: {
        ...mapActions({
            users: 'users',
            user: 'user',
            userStatistics: 'userStatistics'
        }),
        async redirectToUser(data) {
            this.userStatistics(data.uuid);
            
            await this.user(data.uuid).then(() => {
                this.$router.push('/dashboard/user/' + data.uuid);
            })
        },
        convertDate(data) {
            return m(data.created_at).format('DD/MM/YYYY HH:mm');
        },
        returnSubscriptionsCount(subscriptions) {
            if (subscriptions.length === 0) {
                return [];
            } else {
                const countFilterEnabled = filter(subscriptions, { subscription_state: 1 });

                return countFilterEnabled;
            }
        },
        checkIsConnected(data) {                        
            if (this.getUsersCon.includes(data.ip)) {
                return true;
            }

            return false;
        }
    },
    async mounted() {
        await this.users().then(() => {
            this.getUsers.forEach((u) => {
                if (this.getUsersCon.includes(u.ip)) {
                    this.usersConnected.push(u);
                }
            })
        });
    }
};
</script>