import { api } from '@/services/interceptors';
import router from '../router/index';

export default {
  state: {
    users: [],
    user: null,
    statistics: null,
    userStatistics: null
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getUser(state) {
      return state.user;
    },
    getUsersStatistics(state) {
      return state.statistics;
    },
    getUserStatistics(state) {
      return state.userStatistics;
    },
  },
  actions: {
    users({ commit }) {
      return new Promise((resolve) => {
        api.get('api/users').then((res) => {
            commit('STORAGE_USERS', res.data.users);
            resolve();
        });
      })
    },
    usersStatistics({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/users/statistics').then((res) => {
            commit('STORAGE_USERS_STATISTICS', res.data.statistics);
            resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    userStatistics({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/user/statistics/' + payload).then((res) => {
            commit('STORAGE_USER_STATISTICS', res.data.statistics);
            resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    user({ commit }, payload) {
        api.get('api/user/' + payload).then((res) => {
            commit('STORAGE_USER', res.data.user);
        });
    },
    deleteUser({ commit }, payload) {
      commit('STORE_NOTHING');
      api.delete('api/user/' + payload).then(() => {
        router.push('/dashboard/users');
      });
  },
    updateUserRights({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.put('api/user/rights', payload).then(() => {
        dispatch('user', payload.user_uuid);
      });
    },
    updateUserVerification({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.put('api/user/verification', payload).then(() => {
        dispatch('user', payload.user_uuid);
      });
    },
    updateSupport2FA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.put('api/user/emergency/support', payload).then(() => {
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    updateUserSubscriptions({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.put('api/user/subscriptions', payload).then(() => {
          dispatch('user', payload.user_uuid);
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
  },
  mutations: {
    STORAGE_USERS(currentState, users) {
      currentState.users = users;
    },
    STORAGE_USER(currentState, user) {
      currentState.user = user;
    },
    STORAGE_USERS_STATISTICS(currentState, statistics) {
      currentState.statistics = statistics;
    },
    STORAGE_USER_STATISTICS(currentState, userStatistics) {
      currentState.userStatistics = userStatistics;
    },
    STORE_NOTHING() { }
  }
};