<template>
    <div>
        <div class="flex w-12">
            <div class="flex flex-column gap-2">
                <label for="ticker">Ticker* <span class="pi pi-search ml-1" style="font-size: .7rem"></span></label>
                <AutoComplete v-model="review.ticker" :invalid="!review.ticker" optionLabel="ticker" :suggestions="getSearchTickers" @complete="searchByTickers" placeholder="Search with ticker..." />
            </div>

            <div class="flex flex-column gap-2 w-4 ml-2">
                <label for="name">Name</label>
                <InputText id="name" v-model="review.title" placeholder="Select ticker" disabled />
            </div>

            <div class="flex flex-column gap-2 ml-2">
                <label for="email">User* <span class="pi pi-search ml-1" style="font-size: .7rem"></span></label>
                <AutoComplete :invalid="!review.user_uuid" v-model="review.user_uuid" optionLabel="email" :suggestions="getSearchUsers" @complete="searchByUsers" placeholder="Search with email" />
            </div>

            <div class="flex flex-column gap-2 ml-2">
                <label for="order">Score /100*</label>
                <InputText id="order" v-model="review.score" :disabled='!review.user_uuid' placeholder="Score" />
            </div>
        </div>

        <div class="flex mt-3 w-12">
            <Textarea v-model="review.description" :disabled='!review.user_uuid' rows="5" cols="30" />
        </div>

        <Button
            label="Create review"
            severity="success"
            class="mt-4"
            @click="create()"
            :disabled="!review.user_uuid || !review.ticker || !review.title || !review.score"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { FilterMatchMode } from 'primevue/api';
import m from 'moment';

export default {
    name: 'reviewCreate',
    data() {
        return {
            review: {
                user_uuid: null,
                score: null,
                description: null,
                ticker: null,
                title: null
            }
        };
    },
    watch: {
        'review.ticker' : function() {
            if (this.review.ticker) {
                this.review.title = this.review.ticker.title;
            } else {
                this.review.title = null;
            }
        },
    },
    computed: {
        ...mapGetters({
            getSearchUsers: 'getSearchUsers',
            getSearchTickers: 'getSearchTickers'
        }),
    },
    methods: {
        ...mapActions({
            searchUsers: 'searchUsers',
            searchTickers: 'searchTickers',
            createReview: 'createReview'
        }),
        convertDate(date) {
            return m(date).format('DD/MM/YYYY HH:mm');
        },
        searchByUsers(event) {
            const payload = {
                search: event.query
            }

            this.searchUsers(payload);
        },
        searchByTickers(event) {
            const payload = {
                search: event.query
            }

            this.searchTickers(payload);
        },
        async create() {
            await this.createReview(this.review).then(() => {
                this.review = {
                    user_uuid: null,
                    score: null,
                    description: null,
                    ticker: null,
                    title: null
                }
            });
        }
    },
};
</script>