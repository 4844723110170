<template>
    <div v-if="getIsAuth" class="m-center">
        <Message v-if="ordersAlertUnverified === 1" @click="$router.push('/dashboard/orders')" severity="warn" closable>{{ ordersAlertUnverified }} order is unverified</Message>
        <Message v-if="ordersAlertUnverified > 1" @click="$router.push('/dashboard/orders')" severity="warn" closable>{{ ordersAlertUnverified }} orders are unverified</Message>
        
        <!-- <Message severity="warn" closable>Please not update tickers and bottles. Await update database.</Message> -->

        <div class="card lg:px-9">
            <Menubar :model="items">
                <template #item="{ item, props, hasSubmenu }">
                    <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                        <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                            <span :class="item.icon" />
                            <span class="ml-2">{{ item.label }}</span>
                            <Badge v-if="item.badge" :value="item.badge" severity="danger" class="ml-2" />
                        </a>
                    </router-link>
                    <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
                        <span :class="item.icon" />
                        <span class="ml-2">{{ item.label }}</span>
                        <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
                    </a>
                </template>
            </Menubar>
        </div>
        <Statistics v-if="notDisplayStats" />

        <Toast />

    </div>

    <router-view/>

    <div style="height: 20%" class="border-2 border-dashed surface-border mt-2">
        <div v-for="ping in getPings" :key="ping" class="flex flex-wrap justify-center align-items-center">
            <p class="results-ping"><a @click="ping()" :href="ping.url" target="_blank">{{ ping.url }}</a> : {{ ping.ms }}ms</p>
            <i class="pi ml-1" :class="{ 'pi-check-circle text-green-500': ping.ms < 220, 'pi-exclamation-circle text-orange-400': ping.ms >= 220 && ping.ms < 500, 'pi-times-circle text-red-400': ping.ms > 500 }"></i>
        </div>
        <button v-if="!pingPending" @click.prevent="sendPing()" class="refresh-ping">Refresh</button>
        <i v-else class="pi pi-refresh ping-pending"></i>
    </div>
</template>

<script>  
import Statistics from '@/components/dashboard/statistics.vue';
import { mapActions, mapGetters } from 'vuex';
import { find } from 'lodash';
// import WebSocketManager from '@/services/websocket.js';

export default {
    name: 'mainPage',
    components: {
        Statistics
    },
    data() {
        return {
            items: [
                {
                    label: '',
                    icon: 'pi pi-home',
                    route: '/dashboard'
                },
                {
                    label: 'Security',
                    icon: 'pi pi-desktop',
                    items: [
                        {
                            label: 'Browser & Product',
                            icon: 'pi pi-filter',
                            route: '/dashboard/history/browser'
                        },
                        {
                            label: 'Actions by IP',
                            icon: 'pi pi-flag',
                            route: '/dashboard/history/actions/ip'
                        },
                        {
                            label: 'Backoffice',
                            icon: 'pi pi-table',
                            route: '/dashboard/history/backoffice'
                        },
                        {
                            label: 'Ban list (IP)',
                            icon: 'pi pi-ban',
                            route: '/dashboard/history/banlist'
                        },
                        {
                            label: 'Emergency',
                            icon: 'pi pi-stopwatch',
                            route: '/dashboard/settings/website/emergency'
                        },
                    ]
                },
                // {
                //     label: 'Scraping',
                //     icon: 'pi pi-database',
                //     items: [
                //         {
                //             label: 'Generate XLSX',
                //             icon: 'pi pi-file-excel',
                //             route: '/dashboard/scraping/generate'
                //         },
                //         {
                //             label: 'Autoscraper',
                //             icon: 'pi pi-sitemap',
                //             route: '/dashboard/scraping/autoscraper'
                //         },
                //         {
                //             label: 'Statistics',
                //             icon: 'pi pi-chart-bar',
                //             route: '/dashboard/scraping/statistics'
                //         }
                //     ]
                // },
                {
                    label: 'Normalized',
                    icon: 'pi pi-cloud',
                    items: [
                        {
                            label: 'Generate XLSX',
                            icon: 'pi pi-file-excel',
                            route: '/dashboard/normalized/generate'
                        },
                        {
                            label: 'Tickers list',
                            icon: 'pi pi-ticket',
                            route: '/dashboard/tickers'
                        },
                        {
                            label: 'Statistics',
                            icon: 'pi pi-chart-bar',
                            route: '/dashboard/normalized/statistics'
                        }
                    ]
                },
                {
                    label: 'Website',
                    icon: 'pi pi-sliders-v',
                    items: [
                        {
                            label: 'Users',
                            icon: 'pi pi-users',
                            route: '/dashboard/users'
                        },
                        {
                            label: 'Orders',
                            icon: 'pi pi-list',
                            route: '/dashboard/orders',
                        },
                        {
                            label: 'Reports',
                            icon: 'pi pi-ticket',
                            route: '/dashboard/reports',
                        },
                        {
                            label: 'Blog',
                            icon: 'pi pi-file-edit',
                            route: '/dashboard/settings/website/blog'
                        },
                        {
                            label: 'Bottlers',
                            icon: 'pi pi-file-edit',
                            route: '/dashboard/settings/website/bottler'
                        },
                        {
                            label: 'Events',
                            icon: 'pi pi-calendar-plus',
                            route: '/dashboard/events'
                        },
                        {
                            label: 'Reviews',
                            icon: 'pi pi-star',
                            route: '/dashboard/reviews'
                        },
                        // {
                        //     label: 'Voucher',
                        //     icon: 'pi pi-tag',
                        //     route: '/dashboard/settings/website/voucher'
                        // }
                    ],
                },
                {
                    label: 'Statistics',
                    icon: 'pi pi-chart-line',
                    items: [
                        // {
                        //     label: 'Generate XLSX',
                        //     icon: 'pi pi-file-excel',
                        //     route: '/dashboard/statistics/xlsx'
                        // },
                        {
                            label: 'Board',
                            icon: 'pi pi-list',
                            route: '/dashboard/statistics/board'
                        },
                        {
                            label: 'Charts',
                            icon: 'pi pi-chart-pie',
                            route: '/dashboard/statistics/all'
                        },
                    ]
                },
                {
                    label: 'Storage',
                    icon: 'pi pi-box',
                    route: '/dashboard/storage'
                },
                {
                    label: '',
                    icon: 'pi pi-cog',
                    items: [
                        // {
                        //     label: 'Account details',
                        //     icon: 'pi pi-sliders-h',
                        //     route: '/dashboard/settings/account'
                        // },
                        // {
                        //     label: 'Actions history',
                        //     icon: 'pi pi-history',
                        //     route: '/dashboard/tickers'
                        // },
                        // {
                        //     label: 'Changelogs',
                        //     icon: 'pi pi-wrench',
                        //     route: '/dashboard/changelogs'
                        // },
                        {
                            label: 'Logout',
                            icon: 'pi pi-power-off',
                            command: () => {
                                this.logout();
                            }
                        }
                    ]
                },
                {
                    label: '',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.refresh();
                    }
                },
            ],
            exlusionsStatisticsPages: [
                "user", "orders", "tickers"
            ],
            pingPending: false
        };
    },
    watch: {
        getMissingBottles() {
            this.items[3].items[2].badge = this.getErrorsBottles.length + this.getMissingBottles.length;
        },
        getErrorsBottles() {
            this.items[3].items[2].badge = this.getErrorsBottles.length + this.getMissingBottles.length;
        },
        getUnverifiedOrders() {
            const findOrders = find(this.items[3].items,  { label: 'Orders' });
            findOrders.badge = this.getUnverifiedOrders.length;
        }
    },
    computed: {
        ...mapGetters({
            getIsAuth: 'getIsAuth',
            getMissingBottles: 'getMissingBottles',
            getErrorsBottles: 'getErrorsBottles',
            getUnverifiedOrders: 'getUnverifiedOrders',
            getPings: 'getPings'
        }),
        notDisplayStats() {
            if (this.exlusionsStatisticsPages.includes(this.$route.name)) {
                return false; 
            }
            return true;
        },
        ordersAlertUnverified() {
            return this.getUnverifiedOrders.length;
        }
    },
    methods: {
        ...mapActions({
            ping: 'ping',
            logout: 'logout',
            missingBottles: 'missingBottles',
            errorsBottles: 'errorsBottles',
            historyBrowser: 'historyBrowser',
            historyProducts: 'historyProducts',
            usersStatistics: 'usersStatistics',
            unverified: 'unverifiedOrders',
            scrapingStatistics: 'scrapingStatistics'
        }),
        sendLogout() {
            this.logout();
        },
        async refresh() {
            this.$toast.add({ severity: 'info', summary: ``, detail: 'Refresh in progress', life: 3000 });

            await this.usersStatistics().then(async() => {
                this.pingPending = true;

                this.missingBottles();
                this.errorsBottles();
                this.historyBrowser();
                this.historyProducts();
                this.unverified();
                this.scrapingStatistics();
                await this.ping().finally(() => {
                    this.pingPending = false;
                });

                this.$toast.add({ severity: 'success', summary: ``, detail: 'Refresh OK', life: 3000 });
            });
        },
        async sendPing() {
            this.pingPending = true;

            await this.ping().finally(() => {
                this.pingPending = false;
            });
        }
    },
    async mounted() {
        // WebSocketManager.connectWebSocket();
        
        if (!this.getIsAuth) {
            this.pingPending = true;

            await this.ping().finally(() => {
                this.pingPending = false;
            })
            this.$router.push('/dashboard');
        }

        this.refresh();

        setInterval(async() => {
            await this.ping().finally(() => {
                this.pingPending = false;
            })
        }, 10000);

        setInterval(() => {
            this.refresh();
        }, 300000);
    }
};
</script>

<style>
.results-ping {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px;
}

.refresh-ping {
    margin-top: 5px;
    font-size: 10px;
    padding: 1px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ping-pending {
    animation: rotate 1.5s linear infinite;
    margin-top: 3px;
}
</style>