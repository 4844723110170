<template>
    <div class="card lg:px-9">
        <div class="card">
            <DataTable v-if="getUsersStatistics" v-model:filters="filters" :value="getUsersStatistics.isbn" stripedRows paginator :rows="5" :rowsPerPageOptions="[5, 10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
                <template #header>
                    <div class="flex justify-content-between">
                        <h4>Top search products</h4>
                        <IconField iconPosition="left">
                            <InputIcon>
                                <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </IconField>
                    </div>
                </template>
                <template #empty> No isbn found. </template>
               
                <Column field="isbn" header="ISBN" style="max-width: 2rem"></Column>
                <Column field="count" header="Count" style="max-width: 1rem"></Column>
            </DataTable>
        </div>

        <div class="card">
            <DataTable v-model:filters="filters" :value="getScrapingStats" stripedRows paginator :rows="100" :rowsPerPageOptions="[5, 10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
                <template #header>
                    <div class="flex justify-content-between">
                        <h4>Scrapping status</h4>
                        <IconField iconPosition="left">
                            <InputIcon>
                                <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </IconField>
                    </div>
                </template>
                <template #empty> No website found. </template>
                
                <Column field="website" header="Website" style="max-width: 2rem"></Column>
                <Column field="auctionsDone" header="Auctions done" style="max-width: 2rem"></Column>
                <Column field="auctionsPending" header="Auctions pending" style="max-width: 2rem"></Column>
                <Column field="bottlesDone" header="Bottles" style="max-width: 2rem"></Column>
                <Column field="bottlesPending" header="Bottles pending" style="max-width: 2rem"></Column>
                <Column field="completion" header="Completion" style="max-width: 2rem">
                    <template #body="{ data }">
                        <p>{{ parseFloat(data.completion).toFixed(2) }} %</p>
                    </template>
                </Column>
                <Column field="updatedAt" header="Last update" style="max-width: 3rem">
                    <template #body="{ data }">
                        <p>{{ convertDate(data) }}</p>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>  
import { mapActions, mapGetters } from 'vuex';
import m from 'moment';
import { FilterMatchMode } from 'primevue/api';

export default {
    name: 'dashBoard',
    components: {
    },
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    },
    computed: {
        ...mapGetters({
            getUsersStatistics: 'getUsersStatistics',
            getScrapingStats: 'getScrapingStats'
        }),
    },
    methods: {
        ...mapActions({
            userStatistics: 'userStatistics',
            scrapingStatistics: 'scrapingStatistics'
        }),
        convertDate(data) {
            return m(data.updatedAt).format('DD/MM/YYYY HH:mm');
        },
    },
};
</script>

<style>
    .m-center {
        margin: auto;
    }
</style>