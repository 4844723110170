import { api } from '@/services/interceptors';

export default {
  state: {
    reviews: []
  },
  getters: {
    getReviews(state) {
      return state.reviews;
    }
  },
  actions: {
    reviews({ commit }) {
      return new Promise((resolve) => {
        api.get('api/reviews').then((res) => {
            commit('STORAGE_REVIEWS', res.data.reviews);
            resolve();
        });
      })
    },
    createReview({ commit }, payload) {
        commit('STORE_NOTHING');
        return new Promise((resolve, reject) => {
            api.post('api/reviews', payload).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    updateReview({ commit, dispatch }, payload) {
        commit('STORE_NOTHING');
        api.put('api/reviews', payload).then(() => {
          dispatch('reviews');
        });
      },
    deleteReview({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.delete('api/reviews/' + payload).then(() => {
        dispatch('reviews');
      });
    },
  },
  mutations: {
    STORAGE_REVIEWS(currentState, reviews) {
      currentState.reviews = reviews;
    },
    STORE_NOTHING() { }
  }
};